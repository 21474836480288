import { AllUserRoles } from "app/shared/generic_variables";
import { VillageGroup, VillageType } from "common/typings";

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDExJK33jUt5uzfcH8y0JZhUi9ERKVIopc",
    authDomain: "schoolvillage.firebaseapp.com",
    projectId: "schoolvillage",
    storageBucket: "schoolvillage.appspot.com",
    messagingSenderId: "945375052306",
    appId: "1:945375052306:web:b27b3d7456604d2f8ccecb",
    measurementId: "G-7RWS8VWSQX",
  },
  apiEndPoint: "https://us-central1-schoolvillage.cloudfunctions.net",
};

export const appName = "SchoolVillage Prod";

export const Roles = [
  AllUserRoles.district,
  AllUserRoles.maintenance,
  AllUserRoles.pdFireEms,
  AllUserRoles.admin,
  AllUserRoles.security,
  AllUserRoles.staff,
  AllUserRoles.student,
  AllUserRoles.family,
];

export const strings = {
  appName: "SchoolVillage",
  villageType: VillageType.SCHOOL,
  villageGroup: VillageGroup.District,
};

export const AllAlerts = [
  {
    key: "armed",
    value: "Armed Assailant",
    title: "An ARMED ASSAILANT has been reported",
  },
  {
    key: "fight",
    value: "Fight",
    title: "A FIGHT has been reported",
  },
  {
    key: "fire",
    value: "Fire",
    title: "A FIRE has been reported",
  },
  {
    key: "intruder",
    value: "Intruder",
    title: "An INTRUDER has been reported",
  },
  {
    key: "medical",
    value: "Medical Emergency",
    title: "A MEDICAL EMERGENCY has been reported",
  },
  {
    key: "other",
    value: "Other Emergency",
    title: "An EMERGENCY has been reported",
  },
];
export const UserBulkUploadFileSampleTemplateFileName = "sv-user-bulk-upload-sample.xlsx";
